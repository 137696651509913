import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { SkeletonProps } from './Skeleton'

type SkeletonContainerProps = { fitContainer?: SkeletonProps['fitContainer'] }
export const SkeletonContainer = styled.span<SkeletonContainerProps>(
  ({ fitContainer }) => `
    ${fitContainer ? `width: 100%;` : ''}
`
)

type SkeletonLoadingProps = Pick<
  SkeletonProps,
  | 'bottom'
  | 'height'
  | 'isStatic'
  | 'size'
  | 'variant'
  | 'width'
  | 'removeBorder'
>

const borderByVariant = (theme: ThemeProps) => ({
  circle: '50%',
  rectangle: theme.borderRadius.medium,
  inline: '3.3rem',
  shape: '3.3rem',
})

export const SkeletonLoading = styled.span<SkeletonLoadingProps>(
  ({
    theme,
    variant = 'inline',
    isStatic,
    height,
    width,
    bottom,
    size,
    removeBorder,
  }) => {
    const customHeight = size ?? height ?? '100%'
    const customWidth = size ?? width ?? '100%'

    return `
      @keyframes react-loading-skeleton {
        100% {
          transform: translateX(100%);
        }
      }

      background-color: ${theme.colors.grey.light4};
      border-radius: ${removeBorder ? 0 : borderByVariant(theme)[variant]};
      cursor: progress;
      display: inline-flex;
      height: ${customHeight};
      ${removeBorder ? '' : 'line-height: 1'};
      margin-bottom: ${removeBorder ? 0 : (bottom ?? '0.5rem')};
      overflow: hidden;
      position: relative;
      width: ${customWidth};
      z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

      &::after {
        animation-direction: normal;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: react-loading-skeleton;
        animation-timing-function: ease-in-out;
        background-image: linear-gradient(90deg, ${
          theme.colors.grey.light4
        }, #f5f5f5, ${theme.colors.grey.light4});
        background-repeat: no-repeat;
        content: ' ';
        display: ${isStatic ? 'none' : 'block'};
        height: ${customHeight};
        left: 0;
        position: absolute;
        right: 0;
        transform: translateX(-100%);
      }

      @media (prefers-reduced-motion) {
        &::after {
          animation: none;
          display: none;
        }
      }
    `
  }
)
