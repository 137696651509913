import { useEffect, useState } from 'react'

export const useScrollDirection = () => {
  const [isScrollingUp, setIsScrollingUp] = useState<boolean>(false)

  useEffect(() => {
    let lastScrollY = window.scrollY

    const handleScroll = () => {
      if (window.scrollY < lastScrollY) {
        setIsScrollingUp(true)
      } else {
        setIsScrollingUp(false)
      }
      lastScrollY = window.scrollY
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return isScrollingUp
}
