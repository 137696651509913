import { FC } from 'react'

import { SkeletonContainer, SkeletonLoading } from './styled'
import { SkeletonDimensionsProps, SkeletonShapesProps } from './types'

export type SkeletonProps = SkeletonDimensionsProps &
  SkeletonShapesProps & {
    /**
     * Specify a margin bottom for the line/shape
     */
    bottom?: string
    /**
     * Number of lines/shapes
     */
    count?: number
    /**
     * Specifies a unique name for skeleton
     */
    id?: string
    /**
     * Switch to display inline instead of block
     */
    isInline?: boolean
    /**
     * Defines if there is no animation
     * @default false
     */
    isStatic?: boolean
    /**
     * Remove border radius & margins to fit the parent container
     */
    // eslint-disable-next-line react/boolean-prop-naming
    removeBorder?: boolean
  }

const EmptyShape: FC<{
  children: React.ReactNode
}> = ({ children }) => <>{children}</>

/**
 * @describe Skeleton component used as a loader to indicate that data is loading
 */
export const Skeleton: FC<SkeletonProps> = ({
  bottom,
  count,
  height,
  id = 'skeleton',
  isInline,
  isStatic,
  shape: Shape = EmptyShape,
  size,
  variant = 'inline',
  width,
  fitContainer = false,
  removeBorder,
  ...rest
}) => {
  /**
   * Only set a default-size if variant is circle
   */
  const defaultSize = variant === 'circle' ? size || '8.8rem' : size

  /**
   * Only set a shape if variant is shape
   */
  const DefaultShape = variant === 'shape' ? Shape : EmptyShape

  /**
   * Without the <br />, the skeleton lines will all run together if width is specified
   */
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <SkeletonContainer
          aria-busy={!isStatic}
          aria-live="polite"
          data-testid={`skeleton-${id}`}
          fitContainer={fitContainer}
          key={`skeleton-${id}-${index}`}
          {...rest}
        >
          <DefaultShape>
            <SkeletonLoading
              aria-hidden="true"
              bottom={bottom}
              height={height}
              isStatic={isStatic}
              key={`${id}-${index}`}
              removeBorder={removeBorder}
              size={defaultSize}
              variant={variant}
              width={width}
            >
              &zwnj;
            </SkeletonLoading>
            {!isInline && <br data-testid={`break-${id}`} />}
          </DefaultShape>
        </SkeletonContainer>
      ))}
    </>
  )
}
