import styled from 'styled-components'

import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const SessionMenuContainer = styled.div(
  ({ theme }) => `
    display: inline-block;
    line-height: 20px;
    margin: 0 0.5rem;
    position: relative;
    text-align: initial;
    vertical-align: middle;
    white-space: initial;
    z-index: ${zIndexRegistry.DROPDOWN};

    ${MEDIA_QUERIES(theme).large}{
      margin-left: ${theme.spacing.default};
    }
  `
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const SessionMenuButton = styled.button`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 0;
  padding: 0;
`
