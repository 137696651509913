import {
  isAnyCommunityCategory,
  isAnyPetsCategory,
  isAnyRealEstateCategory,
  isAnyServicesCategory,
  isAnyVacationRentalsCategory,
} from '@kijiji/category'

/**
 * Converts a URL to its non-SEO search results page version by adding the search parameter.
 * This helps distinguish between SEO and non-SEO SRP.
 *
 * @param url - URL or string to be converted
 * @returns string - The modified URL as a string
 */
export const toNonSeoSrpUrl = (url: URL | string, categoryId: number): string => {
  const urlObject = typeof url === 'string' ? new URL(url) : url

  // KJCA-5301: Only enabled for vacation rentals and services categories for now
  // KJCA-6045: Added pets, community, and real estate categories
  if (
    isAnyVacationRentalsCategory(categoryId) ||
    isAnyServicesCategory(categoryId) ||
    isAnyPetsCategory(categoryId) ||
    isAnyCommunityCategory(categoryId) ||
    isAnyRealEstateCategory(categoryId)
  ) {
    urlObject.searchParams.set('search', 'true')
  }

  return urlObject.toString()
}
