type Coordinates = { latitude: number; longitude: number }

type BoundingBox = {
  ne: Coordinates // Northeast corner
  sw: Coordinates // Southwest corner
}

export const getBoundingBoxFromRadius = (
  latitude: number,
  longitude: number,
  radius: number
): BoundingBox => {
  const earthRadius = 6378137 // Earth's radius in meters (WGS84 standard)

  // Convert radius to meters if it's in km
  const radiusInMeters = radius * 1000

  // Convert latitude and longitude from degrees to radians
  const latRad = (latitude * Math.PI) / 180
  const lonRad = (longitude * Math.PI) / 180

  // Angular distance covered by the radius
  const angularDistance = radiusInMeters / earthRadius

  // Calculate min and max latitudes
  const minLat = latRad - angularDistance
  const maxLat = latRad + angularDistance

  // Calculate min and max longitudes
  const minLon = lonRad - angularDistance / Math.cos(latRad)
  const maxLon = lonRad + angularDistance / Math.cos(latRad)

  // Convert back to degrees and define NE & SW points
  return {
    ne: { latitude: (maxLat * 180) / Math.PI, longitude: (maxLon * 180) / Math.PI }, // Top-right
    sw: { latitude: (minLat * 180) / Math.PI, longitude: (minLon * 180) / Math.PI }, // Bottom-left
  }
}
