import styled from 'styled-components'

import { PageContainer } from '@/components/shared/page-container'

export const GlobalMessagesContainer = styled(PageContainer)(
  ({ theme }) => `
  width: 100%;
  margin: ${theme.spacing.default} auto;

  & > div {
    margin-top: ${theme.spacing.default};
  }
`
)
