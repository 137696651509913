import Image, { type ImageProps } from 'next/image'
import { useEffect, useState } from 'react'

import { IMAGE_NOT_AVAILABLE_URL } from '@/constants/others'

export type ImageCustomProps = Omit<ImageProps, 'src'> & {
  src: string
  srcFallback?: string
  /**
   * Specifies if the component should render a plain "img" tag instead of next/image
   */
  skipNextImage?: boolean
}

/**
 * Finds the first valid source URL from the provided sources.
 *
 * @param {...(string | undefined)[]} sources - An array of potential source URLs.
 * @returns {string} The first non-empty, non-undefined source URL, or IMAGE_NOT_AVAILABLE_URL if no valid source is found.
 *
 * @description
 * This function iterates through the provided sources and returns the first one that is:
 * 1. Not undefined
 * 2. Not an empty string (after trimming)
 * If no valid source is found, it returns IMAGE_NOT_AVAILABLE_URL as a fallback.
 *
 * @example
 * const validSrc = getValidSrc(undefined, '', '  ', 'https://example.com/image.jpg');
 * // Returns: 'https://example.com/image.jpg'
 *
 * @example
 * const fallbackSrc = getValidSrc(undefined, '', '  ');
 * // Returns: IMAGE_NOT_AVAILABLE_URL
 */
const getValidSrc = (...sources: (string | undefined)[]): string => {
  return sources.find((source) => source && source.trim() !== '') ?? IMAGE_NOT_AVAILABLE_URL
}

/**
 * A custom Image component that handles fallback sources and errors ONLY for local images.
 *
 * @component
 * @param {ImageCustomProps} props - The props for the ImageCustom component.
 * @param {string} props.src - The primary source URL for the image.
 * @param {string} [props.srcFallback] - An optional fallback source URL if the primary source fails.
 * @param {string} props.alt - The alternative text for the image.
 * @param {...ImageProps} props - Any other props supported by Next.js Image component.
 *
 * @returns {JSX.Element} A Next.js Image component with error handling.
 *
 * @example
 * <ImageCustom
 *   src="/path/to/image.jpg"
 *   srcFallback="/path/to/fallback.jpg"
 *   alt="Description of the image"
 *   width={300}
 *   height={200}
 * />
 */
export const ImageCustom = ({
  src,
  srcFallback,
  alt,
  skipNextImage,
  ...props
}: ImageCustomProps) => {
  const [currentSrc, setCurrentSrc] = useState(getValidSrc(src, srcFallback))
  const [error, setError] = useState(false)

  useEffect(() => {
    setCurrentSrc(getValidSrc(src, srcFallback))
    setError(false)
  }, [src, srcFallback])

  const handleError = () => {
    if (!error) {
      setCurrentSrc(getValidSrc(srcFallback, IMAGE_NOT_AVAILABLE_URL))
      setError(true)
    }
  }

  if (skipNextImage) {
    // eslint-disable-next-line @next/next/no-img-element
    return <img src={currentSrc} onError={handleError} alt={alt} {...props} />
  }

  return <Image src={currentSrc} onError={handleError} alt={alt} {...props} />
}
