import { useSession } from 'next-auth/react'

import { isUserSessionLoading } from '@/features/auth/constants/user'
import { ProfileAvatar } from '@/features/profile/components/ProfileAvatar'

export const SessionAvatar = () => {
  const { data, status } = useSession()

  const isLoading = isUserSessionLoading(status)
  const userSession = data?.user

  if (!isLoading && !userSession?.type) {
    return null
  }

  return (
    <ProfileAvatar
      loading={isUserSessionLoading(status)}
      userId={userSession?.sub}
      userType={userSession?.type}
      userName={userSession?.name}
      variation="HEADER_AND_USER_REVIEWS"
      imageSrc={userSession?.image}
    />
  )
}
