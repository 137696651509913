import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING } from '@/components/shared/page-container/styled'
import { MAX_APP_WIDTH } from '@/constants/others'
import { LEGACY_HP_LG_BREAKPOINT } from '@/constants/pageSettings'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

/**
 * The homepage needs a couple of extra breakpoints
 * If this there is a redesign - check with UI team to follow breakpoints from theme
 */
export const MEDIA_QUERY_HP_LARGE_DESKTOP = `@media screen and (min-width: ${LEGACY_HP_LG_BREAKPOINT}px)`

type HomepageContainerProps = {
  removeVerticalSpacing?: boolean
}

const homepageContainerVerticalSpacing = (theme: ThemeProps) => `
  margin-bottom: ${theme.spacing.husky};
  margin-top: 3rem;

  ${MEDIA_QUERIES(theme).medium} {
    margin-bottom: 6rem;
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    margin-top: ${theme.spacing.default};
    margin-bottom: 3rem;
  }
`

export const HomepageContainer = styled.div<HomepageContainerProps>(
  ({ theme, removeVerticalSpacing }) => `
  max-width: 100%;
  width: 100%;

  ${!removeVerticalSpacing && homepageContainerVerticalSpacing(theme)}

  ${MEDIA_QUERIES(theme).medium} {
    max-width: ${MAX_APP_WIDTH};
    padding-right: 0;
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding-right: ${theme.spacing.husky};
    max-width: ${MAX_APP_WIDTH};
    margin-right: auto;
    margin-left: auto;
    display: flex;
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0 8rem;
  }
`
)

type HomepageSectionWrapperProps = { removeMobilePadding?: boolean }
export const HomepageSectionWrapper = styled(Spacing)<HomepageSectionWrapperProps>(
  ({ theme, removeMobilePadding }) => `
  padding: ${removeMobilePadding ? '0' : `0 ${PAGE_CONTAINER_HORIZONTAL_MOBILE_SPACING}`};
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    padding: ${removeMobilePadding ? '0' : `0 ${theme.spacing.husky}`};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: ${removeMobilePadding ? '0' : `0 0 0 ${theme.spacing.husky}`};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0;
  }
`
)

export const HomepageMain = styled.div(
  ({ theme }) => `
  flex-grow: 1;
  margin-bottom: ${theme.spacing.husky};
  max-width: unset;
  width: 100%;

  ${MEDIA_QUERIES(theme).medium} {
    margin-bottom: 0;
    min-height: 100vh; // to reduce layout shift
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    max-width: calc(100% - 34rem);
    margin-bottom: ${theme.spacing.husky};
  }
`
)

export const HomepageAside = styled.aside(
  ({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-left: ${theme.spacing.husky};
    margin-right: ${theme.spacing.husky};
    padding-top: 0;
    position: relative;

    ${MEDIA_QUERIES(theme).medium} {
      align-items: flex-end;
      flex-direction: row;
      margin-top: ${theme.spacing.husky};
    }

    ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
      align-items: stretch;
      flex-direction: column;
      margin-right: 0;
      margin-top: 0;
      min-width: 30rem;
      padding-top: ${theme.spacing.husky};
    }
`
)

// This is to ensure minimum CLS when the ad is INLINE_S
export const HomepageGptRightrailTopWrapper = styled.div(
  ({ theme }) => `

  ${MEDIA_QUERIES(theme).medium} {
    // 250px (ad height) + 18px (adChoices logo height)
    min-height: ${250 + 18}px;
  }
`
)

export const HomepageHeadingH1 = styled(HeadlineText)(
  ({ theme }) => `
  font-size: ${theme.typography.headline.large.fontSize};
  line-height: ${theme.typography.headline.large.lineHeight};
  padding-left: ${theme.spacing.default};

  ${MEDIA_QUERIES(theme).medium}{
    padding-left: ${theme.spacing.husky};
  }

  ${MEDIA_QUERY_HP_LARGE_DESKTOP} {
    padding: 0 0 0 ${theme.spacing.husky};
  }

  ${MEDIA_QUERIES(theme).xLarge} {
    padding: 0;
  }
`
)
