import { type FC } from 'react'

import { ErrorBoundary } from '@/components/shared/error-boundary'
import { PostAdCTA } from '@/components/shared/global-header/PostAdCTA'
import { LoggedInLinks } from '@/components/shared/global-header/tools/logged-in-links'
import { LoggedOutLinks } from '@/components/shared/global-header/tools/logged-out-links'
import { SessionMenu } from '@/components/shared/global-header/tools/session-menu'
import { ToolsContainer } from '@/components/shared/global-header/tools/styled'
import { useSession } from '@/features/auth/hooks/useSession'

type ToolsProps = {
  showPostAdCTA: boolean
}

export const Tools: FC<ToolsProps> = ({ showPostAdCTA }) => {
  const { optimisticIsAuthenticated } = useSession()

  return (
    <ToolsContainer data-testid="global-header-tools">
      <ErrorBoundary fingerprintId="Tools">
        {optimisticIsAuthenticated ? (
          <>
            <LoggedInLinks />
            <SessionMenu />
          </>
        ) : (
          <LoggedOutLinks />
        )}
      </ErrorBoundary>

      {showPostAdCTA && <PostAdCTA />}
    </ToolsContainer>
  )
}
