import ExternalLinkIcon from '@kijiji/icons/src/icons/ExternalLink'
import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'

export const MenuContentContainer = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  background-color: ${theme.colors.white};
  box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
  border: 0.1rem solid ${theme.colors.grey.light4};
  left: 50%;
  position: absolute;
  top: 4.5rem;
  transform: translateX(-50%);
  user-select: none;

  @media print {
    & {
      border: 0.1rem solid ${theme.colors.grey.light3};
      box-shadow: none;
    }
  }
`
)

export const ContentList = styled.ul`
  font-size: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0.5rem;
  text-align: left;
  width: 24rem;
`

export const ContentListItem = styled.li<{ isWatchList?: boolean }>(
  ({ theme, isWatchList = false }) => `
    border-radius: ${theme.borderRadius.small};
    color: ${theme.colors.grey.primary};
    cursor: pointer;
    font-weight: 500;
    line-height: initial;
    padding: 1.3rem 2rem;

    &:hover {
      background: ${theme.colors.green.light5};
      color: ${theme.colors.green.dark1};
    }

    ${MEDIA_QUERIES(theme).medium} {
      display: ${isWatchList ? 'none' : 'list-item'};
    }
  `
)

// eslint-disable-next-line @kijiji/prefer-link-custom
export const MenuLink = styled.a(
  ({ theme }) => `
  align-items: center;
  color: ${theme.colors.grey.primary};
  display: flex;
  text-decoration: none;
`
)

export const MenuLanguage = styled(MenuLink).attrs({ as: 'span' })``

export const Separator = styled.li(
  ({ theme }) => `
    border-top: 0.1rem ${theme.colors.grey.light4} solid;
    height: 0.1rem;
    margin: ${theme.spacing.mini} 0.5rem;
    overflow: hidden;
  `
)

export const ExternalIcon = styled(ExternalLinkIcon)(
  ({ theme }) => `
    fill: ${theme.colors.blue.primary};
    height: ${theme.spacing.default};
    margin-left: 1rem;
    width: ${theme.spacing.default};
  `
)

// eslint-disable-next-line @kijiji/prefer-button-component
export const LogOutButton = styled.button(
  ({ theme }) => `
  border: none;
  color: ${theme.colors.grey.primary};
  text-align: left;
  width: 100%;
`
)
