import { CATEGORIES } from '@kijiji/category'
import { type StaticImageData } from 'next/image'

import { CATEGORY_ASSETS } from '@/constants/category'
import realEstateHousesRentImg from '~/public/next-assets/images/categories/houses-rent.webp'

type PopularCategoriesStaticData = Array<{
  /**
   * Path for background image
   * */
  assetSrc: StaticImageData
  /**
   * Category Id
   */
  id: number
  /**
   * Some popular cards will have duplicated Id with different seo attributes
   * The idex helps identify which data we are pointing to
   * Should start at 0
   */
  index?: number
  /**
   * SEO attributes in case the popular category needs specific filters
   * selected on the SRP SEO Url
   */
  seoAttributes?: { param: string; attributeId: number }
  /**
   * Category name on SEO URL
   */
  seoUrlCategoryName: string
  /**
   * Category key for the translation
   */
  translationKey: string
}>

export const popularOnAutosStaticData: PopularCategoriesStaticData = [
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID],
    id: CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID,
    seoUrlCategoryName: 'cars-trucks',
    translationKey: 'carsTrucks',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.CLASSIC_CARS_CATEGORY_ID],
    id: CATEGORIES.CLASSIC_CARS_CATEGORY_ID,
    seoUrlCategoryName: 'classic-cars',
    translationKey: 'classicCars',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.RVS_CAMPERS_TRAILERS_CATEGORY_ID],
    id: CATEGORIES.RVS_CAMPERS_TRAILERS_CATEGORY_ID,
    seoUrlCategoryName: 'rv-camper-trailer',
    translationKey: 'rsvCampersTrailers',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.BOATS_WATERCRAFT_CATEGORY_ID],
    id: CATEGORIES.BOATS_WATERCRAFT_CATEGORY_ID,
    seoUrlCategoryName: 'boat-watercraft',
    translationKey: 'boatsWatercraft',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.MOTORCYCLES_CATEGORY_ID],
    id: CATEGORIES.MOTORCYCLES_CATEGORY_ID,
    seoUrlCategoryName: 'motorcycles',
    translationKey: 'motorcycles',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.AUTO_PARTS_TIRES_CATEGORY_ID],
    id: CATEGORIES.AUTO_PARTS_TIRES_CATEGORY_ID,
    seoUrlCategoryName: 'auto-parts-tires',
    translationKey: 'autoParts',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.HEAVY_EQUIPMENT_CATEGORY_ID],
    id: CATEGORIES.HEAVY_EQUIPMENT_CATEGORY_ID,
    seoUrlCategoryName: 'heavy-equipment',
    translationKey: 'heavyEquipment',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.ATVS_SNOWMOBILES_CATEGORY_ID],
    id: CATEGORIES.ATVS_SNOWMOBILES_CATEGORY_ID,
    seoUrlCategoryName: 'atv-snowmobile',
    translationKey: 'atvsSnowmobiles',
  },
]

export const popularOnBuySellStaticData: PopularCategoriesStaticData = [
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.FURNITURE_CATEGORY_ID],
    id: CATEGORIES.FURNITURE_CATEGORY_ID,
    seoUrlCategoryName: 'furniture',
    translationKey: 'furniture',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.HOME_OUTDOOR_CATEGORY_ID],
    id: CATEGORIES.HOME_OUTDOOR_CATEGORY_ID,
    seoUrlCategoryName: 'home-outdoor',
    translationKey: 'homeOutdoor',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.TOOLS_CATEGORY_ID],
    id: CATEGORIES.TOOLS_CATEGORY_ID,
    seoUrlCategoryName: 'tool',
    translationKey: 'tools',
  },
]

export const popularOnRealEstateStaticData: PopularCategoriesStaticData = [
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID],
    id: CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID,
    index: 0,
    seoAttributes: { param: 'apartment__condo', attributeId: 29276001 },
    seoUrlCategoryName: 'apartments-condos',
    translationKey: 'for_rent_apartments_condos',
  },
  {
    assetSrc: realEstateHousesRentImg,
    id: CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID,
    index: 1,
    seoAttributes: { param: 'house', attributeId: 29276001 },
    seoUrlCategoryName: 'apartments-condos',
    translationKey: 'for_rent_houses',
  },
  {
    assetSrc: CATEGORY_ASSETS[CATEGORIES.HOUSES_FOR_SALE_CATEGORY_ID],
    id: CATEGORIES.HOUSES_FOR_SALE_CATEGORY_ID,
    seoUrlCategoryName: 'house-for-sale',
    translationKey: 'for_sale_house',
  },
]
