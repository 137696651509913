/**
 * All internal routes available in webapp or legacy app mentioned in the webapp should be added to AvailableRoutes type.
 *
 * All external routes mentioned in webapp should be added to AvailableExternal Routes.
 *
 * Names should always match translation keys.
 *
 * Please, keep in alphabetical order.
 */

type AvailableRoutes =
  | 'ACCOUNT'
  | 'HOMEPAGE'
  | 'MY_ADS'
  | 'MY_FAVOURITES'
  | 'MY_SAVED_SEARCHES'
  | 'MY_MESSAGES'
  | 'MY_ORDERS'
  | 'MY_PROFILE'
  | 'MY_NOTIFICATIONS'
  | 'POST_AD'
  | 'REGISTER'
  | 'SIGN_IN'

type AvailableExternalRoutes =
  | 'ABOUT'
  | 'ACCESSIBILITY'
  | 'ADMARKT'
  | 'ADVERTISE'
  | 'AD_CHOICES'
  | 'APP_STORE'
  | 'CENTRAL_BADGE'
  | 'CENTRAL_EXPLORE'
  | 'FACEBOOK'
  | 'GOOGLE_PLAY'
  | 'HELP_DESK'
  | 'INSTAGRAM'
  | 'JOIN_US'
  | 'MEMBER_BENEFITS'
  | 'MY_BUSINESS'
  | 'PERFORMANCE'
  | 'POSTING_POLICY'
  | 'PRIVACY_POLICY'
  | 'TERMS_OF_USE'
  | 'TWITTER'
  | 'YOUTUBE'

export type Route = {
  /**
   * Path in webapp application
   */
  href?: string
  /**
   * Path in legacy FES/BOX application
   */
  legacyHref?: string
  /**
   * Defines what href should be used depending on:
   * The current environment, if the migration of page has started and if is production ready.
   */
  useLegacyHref: boolean
  /**
   * Defines what are the sub-routes of the main route being defined.
   * Should have the same structure as the main route.
   */
  subRoutes?: {
    [x: string]: Route
  }
}

type ExternalRoutes = {
  [routeName in AvailableExternalRoutes]: { href: string }
}

type Routes = {
  [routeName in AvailableRoutes]: Route
}

/**
 * Routes "href" and "legacyHref" should point to the translation key inside of "routes.json" file.
 *
 * Translation keys should match route keys.
 */

export const EXTERNAL_ROUTES: ExternalRoutes = {
  ABOUT: {
    href: 'external.about.href',
  },
  ACCESSIBILITY: {
    href: 'external.accessibility.href',
  },
  AD_CHOICES: {
    href: 'external.ad_choices.href',
  },
  ADMARKT: {
    href: 'external.admarkt.href',
  },
  ADVERTISE: {
    href: 'external.advertise.href',
  },
  APP_STORE: {
    href: 'external.app_store.href',
  },
  CENTRAL_BADGE: {
    href: 'external.central_badge.href',
  },
  CENTRAL_EXPLORE: {
    href: 'external.central_explore.href',
  },
  FACEBOOK: {
    href: 'external.facebook.href',
  },
  GOOGLE_PLAY: {
    href: 'external.google_play.href',
  },
  HELP_DESK: {
    href: 'external.help_desk.href',
  },
  INSTAGRAM: {
    href: 'external.instagram.href',
  },
  JOIN_US: {
    href: 'external.join_us.href',
  },
  MEMBER_BENEFITS: {
    href: 'external.member_benefits.href',
  },
  MY_BUSINESS: {
    href: 'external.my_business.href',
  },
  PERFORMANCE: {
    href: 'external.performance.href',
  },
  POSTING_POLICY: {
    href: 'external.posting_policy.href',
  },
  PRIVACY_POLICY: {
    href: 'external.privacy_policy.href',
  },
  TERMS_OF_USE: {
    href: 'external.terms_of_use.href',
  },
  TWITTER: {
    href: 'external.twitter.href',
  },
  YOUTUBE: {
    href: 'external.youtube.href',
  },
}

const shouldUseLegacyHref = (hasStartedMigration: boolean, isProductionReady: boolean): boolean => {
  /**
   * If page is production ready on webapp, it should always render webapp's href
   *  */
  if (isProductionReady) {
    return false
  }

  /**
   * If page is not production ready because migration haven't started yet, it should always default to legacy page
   *  */
  if (!hasStartedMigration) {
    return true
  }
  /**
   * If the page is not production ready, but migration has started, then it depends on the current environment.
   * If development/test, then should show webapp routes
   * If production, then should show legacy routes.
   *
   * This logic is defined by env variable: NEXT_PUBLIC_SHOW_WIP_ROUTES
   */
  return process.env.NEXT_PUBLIC_SHOW_WIP_ROUTES !== 'true'
}

/**
 * Definition of all account sub-routes
 */
type AvailableAccountRoutes = 'MANAGE' | 'PERSONAL_INFORMATION' | 'PREFERENCES' | 'SOCIAL_LINKS'
type AccountRoutes = {
  [routeName in AvailableAccountRoutes]: Route
}
const ACCOUNT_ROUTES: AccountRoutes = {
  MANAGE: {
    href: 'account.sub_routes.manage.href',
    useLegacyHref: shouldUseLegacyHref(true, false),
  },
  PERSONAL_INFORMATION: {
    href: 'account.sub_routes.personal_information.href',
    useLegacyHref: shouldUseLegacyHref(true, false),
  },
  PREFERENCES: {
    href: 'account.sub_routes.preferences.href',
    useLegacyHref: shouldUseLegacyHref(true, false),
  },
  SOCIAL_LINKS: {
    href: 'account.sub_routes.social_links.href',
    useLegacyHref: shouldUseLegacyHref(true, false),
  },
}

export const ROUTES: Routes = {
  ACCOUNT: {
    href: `account.href`,
    legacyHref: `account.href_legacy`,
    useLegacyHref: shouldUseLegacyHref(false, false),
    subRoutes: ACCOUNT_ROUTES,
  },
  HOMEPAGE: {
    legacyHref: 'homepage.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_ADS: {
    legacyHref: 'my_ads.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_FAVOURITES: {
    legacyHref: 'my_favourites.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_SAVED_SEARCHES: {
    legacyHref: 'my_saved_search.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_MESSAGES: {
    legacyHref: 'my_messages.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_NOTIFICATIONS: {
    legacyHref: 'my_notifications.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_ORDERS: {
    legacyHref: 'my_orders.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  MY_PROFILE: {
    legacyHref: 'my_profile.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  POST_AD: {
    legacyHref: 'post_ad.href_legacy',
    useLegacyHref: shouldUseLegacyHref(false, false),
  },
  REGISTER: {
    href: 'register.href',
    legacyHref: 'register.href_legacy',
    useLegacyHref: shouldUseLegacyHref(true, true),
  },
  SIGN_IN: {
    // Webapp does not have a path as it's a button that initiates the CIS flow
    legacyHref: 'sign_in.href_legacy',
    useLegacyHref: shouldUseLegacyHref(true, true),
  },
}
