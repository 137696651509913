import { CATEGORIES } from '@kijiji/category'
import SvgChevron from '@kijiji/icons/src/icons/Chevron'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { type FC, type MouseEvent, useEffect, useRef } from 'react'

import { attachRadialLocationParams } from '@/domain/location/attachRadialLocationParams'
import { type CategoryTreeProps } from '@/features/navigation/components/mega-menu/desktop/CategoryTree'
import {
  SubCategoryCampaignImg,
  SubCategoryCampaignLink,
  SubCategoryLink,
  SubCategoryListItem,
} from '@/features/navigation/components/styled'
import { useMenuNavigation } from '@/features/navigation/hooks/useMenuNavigation'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { useHomepageGetSeoUrl } from '@/hooks/useHomepageGetSeoUrl'
import { RemoteParamKeys } from '@/lib/firebase/config'
import { useToggle } from '@/lib/firebase/hooks/useToggle'
import { trackEvent } from '@/lib/ga'
import { type GaEvent, GA_EVENT } from '@/lib/ga/constants/gaEvent'

type SubCategoriesProps = Pick<
  CategoryTreeProps,
  | 'activeL2Items'
  | 'activeL3Items'
  | 'brandedCategoryCampaign'
  | 'isCollapsedMenuView'
  | 'isKeyboardNavigation'
  | 'openMenu'
  | 'selectedL2Item'
> & {
  categoryLevel: number
  parentCategoryId: number
  parentCategoryName: string
  parentSeoUrl: string
  closeMenu: (level: number) => void
}

/**
 * This component is used to display the list of items for L2 or L3 items
 * On Mobile AND Desktop view
 */
export const SubCategories: FC<SubCategoriesProps> = ({
  activeL2Items,
  activeL3Items,
  brandedCategoryCampaign,
  categoryLevel,
  isCollapsedMenuView,
  isKeyboardNavigation,
  openMenu,
  parentCategoryId,
  parentCategoryName,
  parentSeoUrl,
  selectedL2Item,
  closeMenu,
}) => {
  const { location } = useGetLocation()
  const { t } = useTranslation('global_header')

  const { seoLocation } = useHomepageGetSeoUrl({
    locationArea: location.area,
    locationId: location.id,
  })

  const autosLandingPageFlag = useToggle(RemoteParamKeys.AUTOS_LANDING_PAGE_LINK)

  const seeAllLinkRef = useRef<HTMLAnchorElement>(null)
  // set focus to See All Link when submenu first opened
  useEffect(() => {
    if (seeAllLinkRef.current && isKeyboardNavigation.current) {
      seeAllLinkRef.current.focus()
    }
  }, [isKeyboardNavigation])

  const onMouseEnter = (categoryId: number) => {
    /**
     * It should not open the next level on mouse enter
     * only on Click when displaying the modal
     */
    if (categoryLevel === 3 || isCollapsedMenuView) return

    openMenu(categoryId, 2)
  }

  const router = useRouter()
  const { navigateToPage } = useMenuNavigation()

  const handleLinkClick = (
    e: MouseEvent<HTMLAnchorElement>,
    categoryId: number,
    isLeaf?: boolean
  ) => {
    if (categoryLevel !== 3 && !isLeaf) {
      e.preventDefault()
      openMenu(categoryId, 2, true, true)
      return
    }

    e.preventDefault()
    trackEvent({ action: `catId=${categoryId}` as GaEvent, label: `btn="menu"` })

    const url = e.currentTarget.getAttribute('href') || ''

    // Close menu when we are on the Srp page because of the shallow routing
    if (router.pathname !== '/') {
      closeMenu(2) // Close both L2 and L3 menus
    }

    navigateToPage(url)
  }

  const handleSeeAllLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    navigateToPage(attachRadialLocationParams(parentSeoUrl, location))
  }

  const handleBrandedTileTracking = () => {
    trackEvent({
      action: GA_EVENT.BrandedCategorySelected,
      label: `btn=CategoryDropdown;to=BrandedSearch;campaignID=${brandedCategoryCampaign?.id};`,
    })
  }

  const menu = categoryLevel === 2 ? activeL2Items : categoryLevel === 3 ? activeL3Items : []
  const isSubTree = categoryLevel === 3

  if (!menu) return

  return (
    <>
      {/* "See All" link  */}
      <SubCategoryListItem role="none" isSubTree={isSubTree}>
        <SubCategoryLink
          $isSubTree={isSubTree}
          href={attachRadialLocationParams(parentSeoUrl, location)}
          noStyle
          onMouseEnter={() => onMouseEnter(0)}
          ref={seeAllLinkRef}
          role="menuitem"
          onClick={handleSeeAllLinkClick}
        >
          {t('mega_menu.nav.see_all_of_category', { categoryName: parentCategoryName })}
        </SubCategoryLink>
      </SubCategoryListItem>

      {/* Branded Tiles Campaigns */}
      {parentCategoryId === brandedCategoryCampaign?.hpCategory && (
        <SubCategoryListItem role="none">
          <SubCategoryCampaignLink
            href={attachRadialLocationParams(
              brandedCategoryCampaign.internalUrl || brandedCategoryCampaign.srpUrl || '',
              location
            )}
            onClick={handleBrandedTileTracking}
            onMouseEnter={() => onMouseEnter(0)}
            role="menuitem"
          >
            <SubCategoryCampaignImg
              src={brandedCategoryCampaign.logo}
              alt={brandedCategoryCampaign.srpKeyword}
            />
            {brandedCategoryCampaign.label}
          </SubCategoryCampaignLink>
        </SubCategoryListItem>
      )}

      {menu?.map((item) => {
        if (!item) return null

        /**
         * Running experiment to skip Autos landing page (/cars)
         * and go directly to Cars & Trucks SRP
         * TODO: if test successful, update Anvil to return the correct URL
         */
        const shouldSkipAutosLandingPage =
          item.id === CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID && autosLandingPageFlag?.enabled

        const seoUrl = shouldSkipAutosLandingPage
          ? `/b-cars-trucks/${seoLocation.seoCurrentName}/c${item.id}l${seoLocation.id}`
          : item.seoUrl

        let expandableLinkAttr = {}
        if (categoryLevel === 2 && !item.isLeaf) {
          expandableLinkAttr = {
            ['aria-controls']: `cat-menu-group-${item.id}`,
            ['aria-expanded']: selectedL2Item?.id === item.id,
            ['aria-haspopup']: 'true',
          }
        }

        return (
          <SubCategoryListItem
            isActive={selectedL2Item?.id === item.id}
            isSubTree={isSubTree}
            key={`sub-category-l${categoryLevel}-${item?.id}`}
            onMouseEnter={() => onMouseEnter(item.id)}
            role="none"
          >
            <SubCategoryLink
              {...expandableLinkAttr}
              $isSubTree={isSubTree}
              href={attachRadialLocationParams(seoUrl, location)}
              id={`cat-menu-item-${item.id}`}
              noStyle
              onClick={(e) => handleLinkClick(e, item.id, item.isLeaf)}
              role="menuitem"
            >
              {item.categoryName}
              {!item.isLeaf && categoryLevel === 2 && (
                <span>
                  <SvgChevron aria-hidden />
                </span>
              )}
            </SubCategoryLink>
          </SubCategoryListItem>
        )
      })}
    </>
  )
}
