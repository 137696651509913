import UserOutlineIcon from '@kijiji/icons/src/icons/UserOutline'
import Image from 'next/image'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { ProfileAvatarContainer } from '@/features/profile/components/styled'
import { AVATAR_VARIATIONS } from '@/features/profile/constants/profile'
import { isAutosDealerProfile } from '@/features/profile/utils/isAutosDealerProfile'
import { BodyText } from '@/ui/atoms/body-text'
import { Skeleton } from '@/ui/atoms/skeleton'
import { isProfessional } from '@/utils/user'

const FALLBACK_AVATAR_LABEL = 'K'

export type ProfileAvatarProps = {
  loading?: boolean
  imageSrc?: string | null
  userId?: string
  userName?: string | null
  userType?: string
  variation: keyof typeof AVATAR_VARIATIONS
}

export const ProfileAvatar: FC<ProfileAvatarProps> = ({
  loading,
  imageSrc,
  userId,
  userName,
  userType,
  variation,
}) => {
  const { colors } = useTheme()
  const { iconSize, labelSize, size } = AVATAR_VARIATIONS[variation]

  const getAvatarToDisplay = () => {
    if (userType && (isProfessional(userType) || isAutosDealerProfile(userType))) {
      return <UserOutlineIcon aria-hidden data-testid="avatar-pro-icon" />
    }

    if (imageSrc) {
      return <Image src={imageSrc} alt="" fill style={{ objectFit: 'cover' }} />
    }

    /** Fallback to fist letter of the user's name */
    return (
      <BodyText size={labelSize} color={colors.purple.light1}>
        {userName?.[0]?.toUpperCase() ?? FALLBACK_AVATAR_LABEL}
      </BodyText>
    )
  }

  return (
    <ProfileAvatarContainer
      data-testid="profile-avatar"
      iconSize={iconSize}
      size={size}
      userId={userId}
    >
      {loading ? (
        <Skeleton fitContainer variant="circle" data-testid="skeleton-avatar" />
      ) : (
        getAvatarToDisplay()
      )}
    </ProfileAvatarContainer>
  )
}
