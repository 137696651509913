import {
  type AppliedAdditionalFlagsFilter,
  type AppliedAttributeFilter,
  type AppliedDateFilter,
  type AppliedDateRangeFilter,
  type AppliedFilter,
  type AppliedRangeFilter,
  type AppliedToggleFilter,
  type AttributeFilter,
  type AutosDealerAmountPrice,
  type AutosListing,
  type DateFilter,
  type GetSearchResultsPageByUrlQuery,
  type ListingFragment,
  type ListingPrice,
  type MinMax,
  type RangeControlValues,
  type RangeFilter,
  type StartEndDate,
  type ToggleFilter,
} from '@kijiji/generated/graphql-types'

import { type PROMOTIONAL_LISTING_AD_SOURCE, FILTER_CANONICAL } from '@/constants/search'
import { objectHasOwnPolyfill } from '@/utils/object'

//Applies to all filter types containing the __typename property
export type WithTypename<T> = T & { __typename?: string }

type FilterParam = AttributeFilter | RangeFilter | DateFilter | ToggleFilter

export const isAppliedRangeFilter = (filter: AppliedFilter): filter is AppliedRangeFilter =>
  !!filter && (filter as AppliedRangeFilter).__typename === 'AppliedRangeFilter'

export const isAppliedDateRangeFilter = (filter: AppliedFilter): filter is AppliedDateRangeFilter =>
  !!filter && (filter as AppliedDateRangeFilter).__typename === 'AppliedDateRangeFilter'

export const isAppliedAttributeFilter = (filter: AppliedFilter): filter is AppliedAttributeFilter =>
  !!filter &&
  ((filter as AppliedAttributeFilter).__typename === 'AppliedAttributeFilter' ||
    !!(filter as AppliedAttributeFilter).values)

export const isAppliedAdditionalFlagsFilter = (
  filter: AppliedFilter
): filter is AppliedAdditionalFlagsFilter =>
  !!filter &&
  ((filter as AppliedAdditionalFlagsFilter).__typename === 'AppliedAdditionalFlagsFilter' ||
    !!(filter as AppliedAdditionalFlagsFilter).flags)

export const isAppliedDateFilter = (filter: AppliedFilter): filter is AppliedDateFilter =>
  !!filter &&
  ((filter as AppliedDateFilter).__typename === 'AppliedDateFilter' ||
    !!(filter as AppliedDateFilter).value)

export const isAppliedToggleFilter = (filter: AppliedFilter): filter is AppliedToggleFilter =>
  !!filter && (filter as AppliedToggleFilter).__typename === 'AppliedToggleFilter'

export const isMinMaxType = (rangeValue: RangeControlValues | string[]): rangeValue is MinMax =>
  (rangeValue as RangeControlValues)?.__typename === 'MinMax'

export const isStartEndType = (
  rangeValue: RangeControlValues | string[]
): rangeValue is StartEndDate => (rangeValue as RangeControlValues)?.__typename === 'StartEndDate'

/** Strips __typename to ensure that filter data is compatible with the expected Anvil input type
 * (i.e. AttributeFilterInput, RangeFilterInput) */
export const stripTypename = <T>(filter: WithTypename<T>): T => {
  const filterCopy = { ...filter }
  delete filterCopy.__typename
  return filterCopy
}

export const stripTypenames = <T>(filters: WithTypename<T>[]) =>
  filters.map((filter) => stripTypename(filter))

export const isCategoryTree = (filter?: FilterParam): filter is AttributeFilter => {
  return filter?.type === 'TREE_SELECT' && filter?.name === FILTER_CANONICAL.CATEGORY
}

export const isLocationTree = (filter?: FilterParam): filter is AttributeFilter => {
  return filter?.type === 'TREE_SELECT' && filter?.name === FILTER_CANONICAL.LOCATION
}

export const isRangeFilter = (filter?: FilterParam): filter is RangeFilter =>
  !!filter && (filter as RangeFilter).__typename === 'RangeFilter'

export type RangeFilterMinMax = Omit<RangeFilter, 'selectedRangeValues'> & {
  selectedRangeValues?: MinMax | null
}

export type DateRangeFilter = Omit<RangeFilter, 'selectedRangeValues'> & {
  selectedRangeValues?: StartEndDate | null
}

export type HasFilter = AttributeFilter

export const isRangeFilterWithMinMax = (
  filter?: AttributeFilter | RangeFilter | DateFilter
): filter is RangeFilterMinMax => filter?.__typename === 'RangeFilter' && filter?.type === 'RANGE'

export const isDateRangeFilter = (
  filter?: AttributeFilter | RangeFilter | DateFilter
): filter is DateRangeFilter => {
  return filter?.__typename === 'RangeFilter' && filter?.type === 'DATE_RANGE'
}

export const isHasFilter = (filter?: AttributeFilter): filter is HasFilter => {
  return filter?.__typename === 'AttributeFilter' && filter?.type === 'HAS_FILTERS'
}

export const isAttributeFilter = (
  filter: AttributeFilter | RangeFilter | DateFilter
): filter is AttributeFilter => objectHasOwnPolyfill(filter, 'values')

export const isDateFilter = (filter?: FilterParam): filter is DateFilter =>
  !!filter && (filter as DateFilter).__typename === 'DateFilter'

export const isToggleFilter = (filter?: FilterParam): filter is ToggleFilter =>
  !!filter && (filter as ToggleFilter).__typename === 'ToggleFilter'

export const isAutosListing = (listing: ListingFragment): listing is AutosListing =>
  !!listing && (listing as AutosListing).__typename === 'AutosListing'

export const isAutosDealerAmountPrice = (
  price?: ListingPrice | null
): price is AutosDealerAmountPrice =>
  !!price && (price as AutosDealerAmountPrice)?.__typename === 'AutosDealerAmountPrice'

export type SearchFiltering =
  GetSearchResultsPageByUrlQuery['searchResultsPageByUrl']['controls']['filtering']

export type AppliedFilters =
  GetSearchResultsPageByUrlQuery['searchResultsPageByUrl']['searchQuery']['filters']

export type SearchFilterGroup = SearchFiltering extends (infer U)[] ? U : never
export type SearchFilter = SearchFilterGroup['filters'] extends (infer U)[] ? U : never
export type Listing = ListingFragment

export type PromotionListingAdSource = keyof typeof PROMOTIONAL_LISTING_AD_SOURCE

export type ResultsCluster = NonNullable<
  NonNullable<GetSearchResultsPageByUrlQuery['searchResultsPageByUrl']['results']>['clusters']
>
