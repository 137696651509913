import {
  isAnyCarsVehiclesCategory,
  isAnyJobsCategory,
  isAnyRealEstateCategory,
} from '@kijiji/category'
import { type TFunction } from 'i18next'
import { Trans } from 'next-i18next'
import { type ReactNode } from 'react'

import { LinkCustom } from '@/components/shared/link-custom/LinkCustom'

const safetyLink = (t: TFunction, descriptionKey: string) => (
  <Trans
    i18nKey={descriptionKey}
    components={[
      <LinkCustom
        hasUnderline
        href={t('routes:external.safety_disclaimer.href')}
        key="r2s-success-description"
        noChildren
        target="_blank"
        variant="secondary"
      />,
    ]}
  />
)

/**
 * Returns the success message title & description based on the category of the listing and the seller profile
 *
 * Uses TRANSLATION_KEYS.R2S & TRANSLATION_KEYS.ROUTES on returned translations
 */
export const getR2SSuccessMessage = (
  t: TFunction,
  categoryId: number,
  isProfessionalSeller?: boolean,
  email?: string
): {
  title: string
  description: string | ReactNode
} => {
  const baseTitleIntl = `r2s:message_form.success.title`
  const baseDescriptionIntl = `r2s:message_form.success.description`

  const messageTitle = isProfessionalSeller
    ? t(`${baseTitleIntl}.professional`)
    : t(`${baseTitleIntl}.generic`)

  const response = { title: messageTitle }

  // if for some reason we don't have the user email, show a generic description with a safety message
  if (isProfessionalSeller && !email) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.generic`) }
  }

  // if the seller is a professional, we inform the user where the email was set
  // messages vary slightly based on category
  if (isProfessionalSeller) {
    const baseDescriptionProfessional = `${baseDescriptionIntl}.professional`

    if (isAnyCarsVehiclesCategory(categoryId)) {
      return { ...response, description: t(`${baseDescriptionProfessional}.vehicles`, { email }) }
    }

    if (isAnyRealEstateCategory(categoryId)) {
      return {
        ...response,
        description: t(`${baseDescriptionProfessional}.real_estate`, { email }),
      }
    }

    return { ...response, description: t(`${baseDescriptionProfessional}`, { email }) }
  }

  // if the seller is not a professional, we show a description with a safety message
  // messages vary slightly based on category
  if (isAnyCarsVehiclesCategory(categoryId)) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.vehicles`) }
  }

  if (isAnyRealEstateCategory(categoryId)) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.real_estate`) }
  }

  if (isAnyJobsCategory(categoryId)) {
    return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.jobs`) }
  }

  return { ...response, description: safetyLink(t, `${baseDescriptionIntl}.generic`) }
}
