import { ThemeProps } from '@kijiji/theme'
import { forwardRef, HTMLAttributes, PropsWithChildren, ReactNode } from 'react'
import { useTheme } from 'styled-components'

import { BodyText } from '@/ui/atoms/body-text'

import { ListItemBase } from './styled'

export type ListItemProps = HTMLAttributes<HTMLLIElement> & {
  /**
   * Constraint: variant = 'bullet'
   * Color of the bullet
   */
  bulletColor?: string
  /**
   * Constraint: variant = 'icon'
   * Icon to be rendered next to text
   */
  icon?: ReactNode
  /**
   * Spacing between icon and text for each item
   */
  listItemGap?: string
  /**
   * Size of the icon
   * */
  iconSize?: string
  /**
   * Constraint: variant = 'icon' | 'text'
   * Secondary text of item
   */
  secondaryText?: string | ReactNode
  /**
   * Size of;
   *   - primaryText (children) with no secondaryText
   *   - secondaryText if specified
   * @default "medium"
   */
  size?: keyof ThemeProps['typography']['body']
  /**
   * Type of item to be rendered
   */
  variant?: 'bullet' | 'icon' | 'text'
} & (
    | {
        bulletColor?: string
        icon?: never
        secondaryText?: never
        variant: 'bullet'
      }
    | {
        bulletColor?: never
        icon: ReactNode
        variant: 'icon'
      }
    | {
        bulletColor?: never
        icon?: never
        variant?: 'text'
      }
  )

type ListItemBodyProps = Pick<ListItemProps, 'secondaryText' | 'size'>

const ListItemBody = ({
  children,
  secondaryText,
  size,
}: PropsWithChildren<ListItemBodyProps>) => {
  const theme = useTheme()

  return (
    <>
      <BodyText
        color={theme.colors.grey.light1}
        size={secondaryText ? 'small' : size}
      >
        {children}
      </BodyText>

      {secondaryText && (
        <BodyText
          as={typeof secondaryText === 'object' ? 'div' : undefined}
          color={theme.colors.grey.primary}
          size={size}
        >
          {secondaryText}
        </BodyText>
      )}
    </>
  )
}

export const ListItem = forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      iconSize = '2.4rem',
      bulletColor,
      children,
      icon,
      secondaryText,
      size = 'medium',
      variant = 'text',
      listItemGap,
      ...rest
    }: ListItemProps,
    forwardedRef
  ) => {
    const theme = useTheme()

    if (variant === 'text') {
      return (
        <ListItemBase
          $iconSize={iconSize}
          $listItemGap={listItemGap ?? theme.spacing.default}
          $bulletColor={bulletColor ?? theme.colors.yellow.primary}
          $size={size}
          $variant={variant}
          ref={forwardedRef}
          {...rest}
        >
          <ListItemBody secondaryText={secondaryText} size={size}>
            {children}
          </ListItemBody>
        </ListItemBase>
      )
    }

    if (variant === 'bullet') {
      return (
        <ListItemBase
          $iconSize={iconSize}
          $listItemGap={listItemGap ?? theme.spacing.default}
          $bulletColor={bulletColor ?? theme.colors.yellow.primary}
          $size={size}
          $variant={variant}
          ref={forwardedRef}
          {...rest}
        >
          {children}
        </ListItemBase>
      )
    }

    return (
      <ListItemBase
        $iconSize={iconSize}
        $listItemGap={listItemGap ?? theme.spacing.default}
        $bulletColor={bulletColor ?? theme.colors.yellow.primary}
        $size={size}
        $variant={variant}
        ref={forwardedRef}
        {...rest}
      >
        {icon}
        <span>
          <ListItemBody secondaryText={secondaryText} size={size}>
            {children}
          </ListItemBody>
        </span>
      </ListItemBase>
    )
  }
)
