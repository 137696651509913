import { showReportDialog } from '@sentry/browser'
import {
  type ErrorInfo,
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useState,
} from 'react'
import { type FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

import { sendToLogger } from '@/utils/sendToLogger'

type ErrorFallbackFlags = {
  /**
   * Flag to hide the error message.
   */
  hideError?: boolean

  /**
   * Flag to hide the "Help fix this issue" button.
   */
  hideHelpFixIssue?: boolean
}

type ErrorFallbackProps = FallbackProps &
  ErrorFallbackFlags & {
    /**
     * Event ID to be passed into the report dialog.
     */
    eventId?: string
  }

const ErrorFallback = ({
  error,
  resetErrorBoundary,
  eventId,
  hideError,
  hideHelpFixIssue,
}: ErrorFallbackProps): ReactElement => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        textAlign: 'center',
      }}
    >
      <h2 style={{ color: '#333', fontSize: '2rem' }}>An unexpected error has occurred.</h2>
      {!hideError && <p style={{ color: 'red', marginBottom: '20px' }}>{error.message}</p>}

      <button
        onClick={resetErrorBoundary}
        style={{
          margin: '10px',
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: 'rgb(55, 51, 115)',
          border: 'none',
          borderRadius: '0.4rem',
          color: '#fff',
          transition: 'background-color 0.3s',
        }}
      >
        Try again
      </button>

      {!hideHelpFixIssue && (
        <button
          onClick={() => showReportDialog({ eventId })}
          style={{
            margin: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: 'pointer',
            backgroundColor: '#f44336',
            border: 'none',
            borderRadius: '0.4rem',
            color: '#fff',
            transition: 'background-color 0.3s',
          }}
        >
          Help fix this issue
        </button>
      )}
    </div>
  )
}

type ErrorBoundaryProps = PropsWithChildren &
  ErrorFallbackFlags & {
    /**
     * Callback function to handle errors.
     */
    onError?: (error: Error, errorInfo: ErrorInfo) => void

    /**
     * Extra data to be passed to the Sentry `sendToLogger` function.
     */
    sentryExtras?: Record<string, unknown>

    /**
     * Unique identifier for the error. Used by Sentry to group errors.
     */
    fingerprintId?: string
  }

/**
 * ErrorBoundary component to handle errors and provide a fallback UI.
 *
 * @returns The React ErrorBoundary component.
 */
export const ErrorBoundary = ({
  onError,
  sentryExtras,
  fingerprintId = 'ErrorBoundary',
  hideError = false,
  hideHelpFixIssue = false,
  ...rest
}: ErrorBoundaryProps): ReactElement => {
  const [eventId, setEventId] = useState<string | undefined>(undefined)

  const onErrorHandle = (error: Error, errorInfo: ErrorInfo): void => {
    if (onError) {
      onError(error, errorInfo)
    }

    // Capture the exception with Sentry and include the React component stack
    // const sentryEventId = captureException(error, {
    const sentryEventId = sendToLogger(error, {
      fingerprint: [fingerprintId],
      contexts: { react: { componentStack: errorInfo.componentStack } },
      ...(sentryExtras ? { sentryExtras } : {}),
    })

    if (sentryEventId) {
      setEventId(sentryEventId)
    }
  }

  const fallbackRenderHandle = useCallback(
    (props: FallbackProps) => {
      return (
        <ErrorFallback
          eventId={eventId}
          hideError={hideError}
          hideHelpFixIssue={hideHelpFixIssue}
          {...props}
        />
      )
    },
    [eventId, hideError, hideHelpFixIssue]
  )

  return (
    <ReactErrorBoundary onError={onErrorHandle} fallbackRender={fallbackRenderHandle} {...rest} />
  )
}
