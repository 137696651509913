import { useGenerateXsrfMutation, useLogoutUserMutation } from '@kijiji/generated/graphql-types'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'

import {
  ContentList,
  ContentListItem,
  ExternalIcon,
  LogOutButton,
  MenuContentContainer,
  MenuLink,
  Separator,
} from '@/components/shared/global-header/tools/session-menu/content/styled'
import { RouteLink } from '@/components/shared/route-link'
import { ToggleLocale } from '@/components/shared/toggle-locale'
import { type Route, ROUTES } from '@/constants/routes'
import { trackGlobalHeaderLanguageSwitch } from '@/domain/globalHeader'
import { sanitizeLogoutUrl } from '@/domain/urls'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { type GaEvent, GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { sendToLogger } from '@/utils/sendToLogger'
import { isAdmarkt } from '@/utils/user'

export type MyKijijiNavItem = {
  route: Route
  gaEvent?: GaEvent
}

export const MY_KIJIJI_NAV_ITEMS: { [key: string]: MyKijijiNavItem } = {
  my_ads: {
    route: ROUTES.MY_ADS,
    gaEvent: GA_EVENT.MyAdsOpen,
  },
  my_favourites: {
    route: ROUTES.MY_FAVOURITES,
  },
  my_orders: {
    route: ROUTES.MY_ORDERS,
    gaEvent: GA_EVENT.MyOrdersOpen,
  },
  my_profile: {
    route: ROUTES.MY_PROFILE,
    gaEvent: GA_EVENT.MyAccountOpen,
  },
  account_settings: {
    route: ROUTES.ACCOUNT,
    gaEvent: GA_EVENT.MyAccountSettingsOpen,
  },
}

export const SessionMenuContent = () => {
  const { data } = useSession()
  const userSession = data?.user

  const { t } = useTranslation(['global_header', 'routes', 'common'])
  const { asPath, push } = useRouter()
  const { routeLocale } = useLocale()

  const [logoutUser] = useLogoutUserMutation()
  const [generateXsrf] = useGenerateXsrfMutation()

  /**
   * Calls anvil mutation generateXsrf
   * @returns XSRF token required for logoutUserLegacy mutation
   */
  const generateXsrfToken = async () => {
    try {
      const xsrfResponse = await generateXsrf()
      const isValidXsrf =
        xsrfResponse.data?.generateXsrf?.statusCode == 200 &&
        xsrfResponse.data?.generateXsrf?.xsrf != null

      if (isValidXsrf) {
        return xsrfResponse.data?.generateXsrf?.xsrf
      }
    } catch (e) {
      sendToLogger(e, {
        tags: { component: 'session-menu-content', fn: 'generate-xsrf-token' },
        fingerprint: ['SessionMenuContent', 'generateXsrfToken'],
      })
    }
    throw new Error('Failed to generate XSRF token')
  }

  /**
   * Calls anvil mutation logoutUser, logging the user out of the legacy application
   */
  const callLogoutUserMutation = async () => {
    try {
      const xsrf = await generateXsrfToken()
      if (!xsrf) {
        throw new Error('XSRF token does not exist')
      }

      await logoutUser({
        variables: {
          input: {
            xsrfToken: xsrf,
          },
        },
      })

      trackEvent({ action: GA_EVENT.LogoutSuccess, label: GA_CTA_HEADER })
    } catch (e) {
      sendToLogger(e, {
        tags: { component: 'session-menu-content', fn: 'sign-out-user-on-legacy' },
        fingerprint: ['SessionMenuContent', 'logoutUser'],
      })
    }
  }

  /**
   * Called once the /auth/signout page loads, this function logs the user out of both
   *              the legacy application (BOX) as well as the Next Webapp
   */
  const logoutHandler = async () => {
    const sanitizedCallbackUrl = sanitizeLogoutUrl(asPath)

    await callLogoutUserMutation()

    push(`/consumer/logout?redirect=${encodeURIComponent(sanitizedCallbackUrl)}`)
  }

  const trackSessionMenuOpen = (key: string) => {
    const gaEvent = MY_KIJIJI_NAV_ITEMS[key].gaEvent

    if (gaEvent) {
      trackEvent({ action: gaEvent, label: GA_CTA_HEADER })
    }
  }

  const trackKijijiMyBusinessOpen = () => {
    trackEvent({ action: GA_EVENT.KijijiMyBusinessOpen, label: GA_CTA_HEADER })
  }

  return (
    <MenuContentContainer data-testid="session-menu-content">
      <ContentList>
        {Object.keys(MY_KIJIJI_NAV_ITEMS).map((key) => (
          <ContentListItem
            data-testid="session-menu-content-item"
            key={key}
            isWatchList={key === 'my_watchlist'}
          >
            <RouteLink route={MY_KIJIJI_NAV_ITEMS[key].route}>
              <MenuLink onClick={() => trackSessionMenuOpen(key)} rel="nofollow">
                {t(`global_header:links.${key}.label`)}
              </MenuLink>
            </RouteLink>
          </ContentListItem>
        ))}
        <Separator role="separator" />

        {isAdmarkt(userSession?.type ?? '') && (
          <>
            <ContentListItem>
              <MenuLink href={`${t(`routes:external.admarkt.href`)}`} rel="nofollow">
                {t(`global_header:links.admarkt.label`)}
              </MenuLink>
            </ContentListItem>
          </>
        )}

        <ContentListItem>
          <MenuLink
            href={`${t('routes:external.my_business.href')}`}
            target="_blank"
            onClick={trackKijijiMyBusinessOpen}
            rel="nofollow"
          >
            {t('global_header:links.kijiji_my_business.label')}
            <ExternalIcon />
          </MenuLink>
        </ContentListItem>

        <Separator role="separator" />
        <ContentListItem>
          <ToggleLocale onClick={() => trackGlobalHeaderLanguageSwitch(routeLocale)}>
            <MenuLink rel="nofollow">{t('common:language_toggle.text')}</MenuLink>
          </ToggleLocale>
        </ContentListItem>

        <Separator role="separator" />
        <ContentListItem>
          <LogOutButton data-testid="header-logout" onClick={logoutHandler}>
            {t('global_header:links.log_out.label')}
          </LogOutButton>
        </ContentListItem>
      </ContentList>
    </MenuContentContainer>
  )
}
