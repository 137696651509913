import LocationServiceOffIcon from '@kijiji/icons/src/icons/LocationServiceOff'
import LocationServiceOnIcon from '@kijiji/icons/src/icons/LocationServiceOn'
import NearbyWalkIcon from '@kijiji/icons/src/icons/NearbyWalk'
import { colors } from '@kijiji/theme'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import { LOCATION_OPTION_TYPE } from '@/constants/location'
import { useFetchLocationFromCoordinates } from '@/hooks/useFetchLocationFromCoordinates'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'

/**
 * Hook to group functions related to extracting live location.
 * The returned function tries to get the user's current location.
 * Updates the shared location state with the new location if successful.
 * Otherwise, triggers the error state.
 * @returns Function to extract out user's current location and update the location modal
 */
export const useLiveLocation = () => {
  const { fetchLocationFromCoordinates } = useFetchLocationFromCoordinates()
  const { t } = useTranslation()
  const searchNearbyToggle = useToggle(RemoteParamKeys.SEARCH_NEARBY_LOCATION)
  const isSearchAroundMeEnabled = searchNearbyToggle?.enabled === true

  const liveLocationOption = useMemo(
    () => ({
      type: LOCATION_OPTION_TYPE.LIVE_LOCATION,
      address: t('modals.search_location.buttons.use_current_location'),
      suggestionId: '',
      icon: isSearchAroundMeEnabled ? (
        <LocationServiceOnIcon
          aria-hidden
          color={colors.purple.light2}
          backgroundColor={colors.purple.light5}
        />
      ) : (
        <LocationServiceOffIcon
          aria-hidden
          color={colors.grey.primary}
          backgroundColor="transparent"
        />
      ),
    }),
    [t, isSearchAroundMeEnabled]
  )

  const nearLiveLocationOption = useMemo(
    () => ({
      type: LOCATION_OPTION_TYPE.NEAR_LIVE_LOCATION,
      address: t('modals.search_location.buttons.near_me'),
      suggestionId: '',
      badge: '✨' + t('modals.search_location.labels.new'),
      icon: (
        <NearbyWalkIcon
          aria-hidden
          color={colors.purple.light2}
          backgroundColor={colors.purple.light5}
        />
      ),
    }),
    [t]
  )

  const onGetLivePositionSuccess = async (geolocation: GeolocationPosition) => {
    const {
      coords: { latitude, longitude },
    } = geolocation
    const newLocation = await fetchLocationFromCoordinates({ latitude, longitude })

    if (!newLocation) {
      return undefined
    }

    return newLocation
  }

  //We need to handle the getCurrentPosition() method synchronously. Consequently it must be wrapped in a Promise.
  const getLivePosition = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
  }

  const getLiveLocation = async () => {
    const isLiveLocationAvailable =
      window !== undefined && window.navigator && 'geolocation' in navigator

    if (!isLiveLocationAvailable) {
      return undefined
    }

    try {
      const geolocation: GeolocationPosition = await getLivePosition()
      return onGetLivePositionSuccess(geolocation)
    } catch {
      return undefined
    }
  }

  return { liveLocationOption, nearLiveLocationOption, getLiveLocation }
}
