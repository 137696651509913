import { CATEGORIES } from '@kijiji/category'
import { type Locale, type UserLocation } from '@kijiji/generated/graphql-types'
import qs from 'query-string'
import { useEffect, useState } from 'react'

import {
  popularOnAutosStaticData,
  popularOnBuySellStaticData,
  popularOnRealEstateStaticData,
} from '@/components/homepage/popular-categories/popularCategoriesStaticData'
import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { ALL_CANADA_LOCATION_ID, DEFAULT_USER_LOCATION } from '@/constants/location'
import locationTree from '@/constants/location/locationTree.json'
import { useLocale } from '@/hooks/useLocale'
import { RemoteParamKeys, useToggle } from '@/lib/firebase/hooks'

export type GetSeoLocationResponse = {
  id: number
  seoCurrentName: string
  seoName: Locale
  queryParameters?: qs.StringifiableRecord
}

/**
 * Multiple homepage components require fetching the SEO URL
 *
 * All the sections should build the seoURL the same way by changing the categoryId
 * We don't want to run "fetch" multiple times on the same page to get the same information
 *
 * This hook abstracts out the logic to avoid running the fetch function multiple times
 */
type HomepageGetSeoUrlProps = {
  locationArea?: UserLocation['area']
  locationId: number
}
export const useHomepageGetSeoUrl = ({ locationArea, locationId }: HomepageGetSeoUrlProps) => {
  const { apiLocale } = useLocale()

  const autosLandingPageFlag = useToggle(RemoteParamKeys.AUTOS_LANDING_PAGE_LINK)
  const [shouldSkipAutosLandingPage, setShouldSkipAutosLandingPage] = useState(false)

  useEffect(() => {
    /**
     * AutosLandingPageFlag.enabled is always false on SSR
     * so we need the useEffect workaround to generate the correct URL
     */
    if (autosLandingPageFlag?.enabled) {
      setShouldSkipAutosLandingPage(true)
    }
  }, [autosLandingPageFlag?.enabled])

  const getSeoLocation = (): GetSeoLocationResponse => {
    const location = locationTree.find((item) => item.id === locationId)

    if (!location) {
      return {
        id: DEFAULT_USER_LOCATION.id,
        seoCurrentName: 'canada',
        seoName: { en_CA: 'canada', fr_CA: 'canada' },
      }
    }

    const response: GetSeoLocationResponse = {
      id: location.id,
      seoCurrentName: location.seoLocation[apiLocale],
      seoName: location.seoLocation,
    }

    if (!locationArea) return response

    response.queryParameters = {
      radius: locationArea.radius,
      address: locationArea.address,
      ll: `${locationArea.latitude},${locationArea.longitude}`,
    }

    return response
  }

  const getAllCategoriesUrl = () => {
    const locationParams = getSeoLocation()
    return qs.stringifyUrl({
      url: `/b-${locationParams.seoCurrentName}/l${locationParams.id}`,
      query: locationParams.queryParameters,
    })
  }

  const addCategoryToSeoUrl = ({
    categoryId,
    index = 0,
  }: {
    categoryId: number
    index?: number
  }): string => {
    const popularCategories = [
      ...popularOnAutosStaticData,
      ...popularOnBuySellStaticData,
      ...popularOnRealEstateStaticData,
    ]
    const categoryData = popularCategories.filter((item) => item.id === categoryId)[index]

    const categorySeoName = categoryData?.seoUrlCategoryName
    const fallbackUrl = `/b-${categorySeoName}/c${categoryId}`

    const seoLocation = getSeoLocation()
    const allCategoriesUrl = getAllCategoriesUrl()

    /**
     * When the locationId can't be found, it will return "All Canada"
     * If returns all canada, but that is not the current user location, then return a locationless URL
     */
    if (
      seoLocation.id === ALL_CANADA_LOCATION_ID &&
      seoLocation.id !== locationId &&
      !!categoryData
    ) {
      return fallbackUrl
    }

    /**
     * If there is no category data, but there is a location defined
     * Then return the URL for "all categories" in the correct location
     */
    if (!categoryData) return allCategoriesUrl

    const { id, seoCurrentName, queryParameters } = getSeoLocation()

    /**
     * If the link has selected filters, those are build in differently on the URL
     */
    if (categoryData?.seoAttributes) {
      const newAttributeUrl = `/b-${categorySeoName}/${seoCurrentName}/${categoryData.seoAttributes.param}/c${categoryId}l${id}a${categoryData.seoAttributes.attributeId}`
      return qs.stringifyUrl({ url: newAttributeUrl, query: queryParameters })
    }

    const newUrl = `/b-${categorySeoName}/${seoCurrentName}/c${categoryId}l${id}`
    return qs.stringifyUrl({ url: newUrl, query: queryParameters })
  }

  /**
   * This function was built to be used within the HP sections
   */
  const getSRPUrl = (categoryId: number, index?: number): string => {
    if (categoryId === ALL_CATEGORIES_ID_NUM) {
      return getAllCategoriesUrl()
    }

    /**
     * Running experiment to skip Autos landing page
     * and go directly to Cars & Trucks SRP
     */
    if (categoryId === CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID && !shouldSkipAutosLandingPage) {
      return '/cars'
    }

    return addCategoryToSeoUrl({ categoryId, index })
  }

  return { getSRPUrl, seoLocation: getSeoLocation() }
}
