import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import {
  ConnectionWrapper,
  LanguageSwitch,
  TextContainer,
} from '@/components/shared/global-header/tools/logged-out-links/styled'
import { HiddenMobile } from '@/components/shared/global-header/tools/styled'
import { ToggleLocale } from '@/components/shared/toggle-locale'
import { trackGlobalHeaderLanguageSwitch } from '@/domain/globalHeader'
import { useSignin } from '@/features/auth/hooks/useSignin'
import { useLocale } from '@/hooks/useLocale'
import { trackEvent } from '@/lib/ga'
import { GA_CTA_HEADER } from '@/lib/ga/constants/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { TextLink } from '@/ui/atoms/text-link'

export const LoggedOutLinks = () => {
  const { t } = useTranslation(['global_header', 'common'])
  const { asPath, push } = useRouter()
  const { routeLocale } = useLocale()
  const [goToSignIn, setGoToSignIn] = useState(false)
  const [goToRegister, setGoToRegister] = useState(false)

  const handleRegisterClick = () => {
    trackEvent({ action: GA_EVENT.UserRegistrationBegin, label: GA_CTA_HEADER })
    setGoToRegister(true)
  }

  const handleSignInClick = () => {
    trackEvent({ action: GA_EVENT.LoginBegin, label: GA_CTA_HEADER })
    setGoToSignIn(true)
  }

  useSignin({ callbackUrl: asPath, goToSignIn })

  useEffect(() => {
    if (goToRegister) {
      push(`/register?redirect=${encodeURIComponent(window.location.href)}`, '/register')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToRegister])

  return (
    <>
      <ToggleLocale onClick={() => trackGlobalHeaderLanguageSwitch(routeLocale)}>
        <LanguageSwitch>{t('common:language_toggle.text.short')}</LanguageSwitch>
      </ToggleLocale>

      <ConnectionWrapper>
        <HiddenMobile>
          <TextLink
            as="button"
            onClick={handleRegisterClick}
            variant="secondary"
            rel="nofollow"
            data-testid="header-register"
          >
            {t('global_header:links.register.label')}
          </TextLink>

          <TextContainer>{t('global_header:or')}</TextContainer>
        </HiddenMobile>

        <TextLink
          as="button"
          key="sign_in"
          variant="secondary"
          onClick={handleSignInClick}
          data-testid="header-sign-in"
        >
          {t('global_header:links.sign_in.label')}
        </TextLink>
      </ConnectionWrapper>
    </>
  )
}
