import {
  GetMapSearchResultsPageStateDocument,
  useGetMapSearchResultsPageStateQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback } from 'react'

export const useMapSRPState = () => {
  /** Read current value from the cache */
  const { data, client } = useGetMapSearchResultsPageStateQuery()
  const { mapSrp: { shouldUpdateLocationRef = false } = {} } = data ?? {}

  const setMapSRPState = useCallback(
    ({ shouldUpdateLocationRef }: { shouldUpdateLocationRef?: boolean }) => {
      client.cache.writeQuery({
        query: GetMapSearchResultsPageStateDocument,
        data: {
          mapSrp: {
            ...data?.mapSrp,
            shouldUpdateLocationRef:
              shouldUpdateLocationRef ?? !!data?.mapSrp.shouldUpdateLocationRef,
          },
        },
      })
    },
    [client.cache, data]
  )

  return {
    setMapSRPState,
    shouldUpdateLocationRef,
  }
}
