import { type ThemeProps } from '@kijiji/theme'

/** AVATAR DEFINITIONS */
type AvatarVariations = 'PROFILE_OVERVIEW' | 'HEADER_AND_USER_REVIEWS'
export const AVATAR_VARIATIONS: {
  [x in AvatarVariations]: {
    iconSize: string
    labelSize: keyof ThemeProps['typography']['body']
    size: string
  }
} = {
  HEADER_AND_USER_REVIEWS: { iconSize: '2.4rem', size: '3.2rem', labelSize: 'small' },
  PROFILE_OVERVIEW: { iconSize: '4rem', size: '6rem', labelSize: 'xLarge' },
}
