import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgKijijiLogoCanada: React.FC<IconProps> = (props) => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      id="kijiji-logo-canada_svg__Layer_1"
      viewBox="0 0 230 120"
      {...props}
    >
      <defs>
        <style>{'.kijiji-logo-canada_svg__st1{fill:#373373}'}</style>
      </defs>
      <path
        d="M69.23 51.62v42.79c0 .84.68 1.52 1.52 1.52h12.09c.84 0 1.52-.68 1.52-1.52V49.93c0-.92-.82-1.63-1.73-1.51l-12.09 1.69c-.75.1-1.31.75-1.31 1.51m63.08 0v42.79c0 .84.68 1.52 1.52 1.52h12.09c.84 0 1.52-.68 1.52-1.52V49.93c0-.92-.82-1.63-1.73-1.51l-12.09 1.69c-.75.1-1.31.75-1.31 1.51m63.08 0v42.79c0 .84.68 1.52 1.52 1.52H209c.84 0 1.52-.68 1.52-1.52V49.93c0-.92-.82-1.63-1.73-1.51l-12.09 1.69c-.75.1-1.31.75-1.31 1.51"
        className="kijiji-logo-canada_svg__st1"
      />
      <path
        d="M76.8 19.34c-5.2 0-9.41 4.21-9.41 9.41s4.21 9.41 9.41 9.41 9.41-4.21 9.41-9.41-4.21-9.41-9.41-9.41"
        fill="#f8aa17"
      />
      <path
        d="M139.88 19.34c-5.2 0-9.41 4.21-9.41 9.41s4.21 9.41 9.41 9.41 9.41-4.21 9.41-9.41-4.21-9.41-9.41-9.41"
        fill="#2681db"
      />
      <path
        d="M171.42 8.72c-5.2 0-9.41 4.21-9.41 9.41s4.21 9.41 9.41 9.41 9.41-4.21 9.41-9.41-4.21-9.41-9.41-9.41"
        fill="#37aa64"
      />
      <path
        d="M202.96 38.16c5.2 0 9.41-4.21 9.41-9.41s-4.21-9.41-9.41-9.41-9.41 4.21-9.41 9.41 4.21 9.41 9.41 9.41"
        fill="#9b44ad"
      />
      <path
        d="M38.31 63.82L58.3 40.13c.85-.99.15-2.51-1.15-2.51H42.11c-.46 0-.89.21-1.18.56L24.41 58.49c-.26.32-.78.14-.78-.28V14.54c0-.84-.68-1.52-1.52-1.52L10.02 14.8c-.93.17-1.52.68-1.52 1.52v78.09c0 .84.68 1.52 1.52 1.52h12.09c.84 0 1.52-.68 1.52-1.52V70.73c0-.46.6-.65.86-.27l17.39 24.83c.28.41.75.65 1.25.65H57.9c1.26 0 1.97-1.44 1.21-2.44L38.32 64.26a.36.36 0 010-.43m75.86-26.02l-12.09 1.69c-.75.1-1.31.75-1.31 1.51v53.92c0 1.65-.08 3.23-.24 4.7-.15 1.42-.51 2.69-1.04 3.77a6.049 6.049 0 01-2.26 2.49c-.98.6-2.28.91-3.88.91a17.372 17.372 0 01-2.65-.23c-1.43-.3-1.89.49-1.95.97-.03.21-.11.73-.11.73-.38 2.73-1 6.89-1.28 9.27 0 0-.26 1.62 1.5 1.93.47.08.98.16 1.59.24 1.22.16 3.02.3 4.22.3 4.35 0 7.94-.7 10.66-2.08 2.72-1.38 4.91-3.26 6.49-5.58 1.58-2.32 2.67-5.07 3.24-8.18.56-3.09.84-6.4.84-9.84V39.32c0-.92-.82-1.63-1.73-1.51m63.45 1.24l-12.09-1.29c-.9-.1-1.68.61-1.68 1.51v55.65c0 1.65-.08 3.23-.24 4.7-.15 1.42-.5 2.69-1.04 3.77-.52 1.05-1.28 1.89-2.26 2.49s-2.29.91-3.88.91a17.22 17.22 0 01-2.65-.23c-1.43-.3-1.89.49-1.95.97-.03.21-.11.73-.11.73-.38 2.73-1 6.89-1.28 9.27 0 0-.26 1.62 1.5 1.93.47.08.98.16 1.59.24 1.22.16 3.02.3 4.22.3 4.35 0 7.94-.7 10.66-2.08 2.72-1.38 4.91-3.26 6.5-5.58 1.58-2.32 2.67-5.07 3.24-8.18.56-3.09.84-6.4.84-9.84V40.56c0-.78-.59-1.43-1.36-1.51m39.14 10.08h-1.1v2.74h-.65v-2.74h-1.09v-.61h2.84v.61zm3.98 2.74h-.66v-2.12l-.09.29-.77 1.83h-.55l-.76-1.83-.1-.29v2.12h-.66v-3.36h.76l.96 2.17.07.19.08-.19.96-2.17h.76v3.36z"
        className="kijiji-logo-canada_svg__st1"
      />
      <path
        d="M108.8 0l3.29 5.53c.13.2.38.28.59.19l2.3-.95-.89 7.93c-.06.46.51.74.83.39l2.73-2.9.45 2.09c.06.26.32.43.58.37l3.61-.85-1.02 4.09c-.05.21.04.43.23.53l1.6.25s-6.03 9.54-13.03 9.54c.04 0-.66.02-1.28.02s-1.24-.03-1.24-.03c-7-.12-13.07-9.53-13.07-9.53l1.6-.25c.19-.11.28-.33.23-.53l-1.02-4.09 3.61.85c.26.06.52-.1.58-.37l.45-2.09 2.73 2.9c.32.34.89.07.83-.39l-.89-7.93 2.3.95c.22.09.47.01.59-.19L108.78 0h.02z"
        fill="#e5262a"
      />
    </Icon>
  )
}
export default SvgKijijiLogoCanada
